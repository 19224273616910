import { gql } from '@apollo/client'; // eslint-disable-line import/no-extraneous-dependencies

// TODO-PLAYLIST Optimiser la query, elle est beaucoup trop lourde
export const GET_PLAYLIST = gql`
  query getPlaylist($playlistId: ID!) {
    playlist(id: $playlistId) {
      id
      isPublished
      disableAnalytic
      hasUniqueChapterFeed
      pageSetting {
        id
        startsAt
        shouldShowSummary
        shouldShowDiscussion
        shouldShowShareButton
        shouldShowTranscript
        shouldShowChapter
        shouldShowFile
        shouldShowPoll
        pageTitle
        videoTitle
        pageText
        pageTheme
        pageLogo
        accentColor
        shouldShowSideBar
        restrictedDomains{id,domain}
      }
      embedSetting {
        id
        startsAt
        shouldShowSummary
        shouldShowDiscussion
        shouldShowShareButton
        shouldShowTranscript
        shouldShowChapter
        shouldShowFile
        shouldShowPoll
        pageTitle
        pageText
        pageTheme
        pageLogo
        accentColor
        shouldShowSideBar
        restrictedDomains{id,domain}
        privacyType
      }
      content {
        id
        type
        language
        media {
          id
          title
          selectedThumb
          sourceUrl
          isEncodingDone
          type
          playlist
          isPublished
          mediaInfos
          confidentiality {
            stringValue
          }
          subtitles {
            id
            languageCode
            url
          }
          publisher {
            id
            cdnEndpoint
          }
          live {
            id
            waitingThumb
            liveUrl
            hasReplay
          }
          discussion {
            id
            preset
          }
          chapters {
            id
          }
          files {
            id
            url
            isActive
            type
          }
          publisher {
            id
            cdnEndpoint
            #   preferences {
            #     id
            #     watermarkEnabled
            #     watermarkPosition
            #     watermarkLink
            #     watermarkImage
            #   }
          }
          pageSetting {
            id
            videoTitle
            pageText
          }
          embedSetting {
            id
            videoTitle
          }
        }
        live {
          id
          status
          title
          description
          liveUrl
          waitingThumb
          startDate
          shouldShowCountdown
          hasDVR
          hasReplay
          poll {
            id
            pollQuestions {
              id
            }
          }
          replay {
            id
            isEncodingDone
            sourceUrl
            selectedThumb
            subtitles {
              id
              languageCode
              url
            }
            publisher {
              id
              cdnEndpoint
              # preferences {
              #   id
              #   watermarkEnabled
              #   watermarkPosition
              #   watermarkLink
              #   watermarkImage
              # }
            }
            live {
              id
              hasReplay
              liveUrl
              waitingThumb
            }
          }
          publisher {
            id
          }
          chapters {
            id
          }
          files {
            id
            url
            isActive
            type
          }
          discussion {
            id
            preset
          }
          pageSetting {
            id
            videoTitle
            pageText
          }
          embedSetting {
            id
            videoTitle
          }
        }
      }
    }
  }
`;
